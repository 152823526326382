import 'bootstrap';

jQuery(document).ready(($) => {
  const onChange = (el) => {
    $('.wpcf7-list-item').removeClass('checked');
    $(el).closest('.wpcf7-list-item').addClass('checked');
  };

  const $feedbackForm = $('.feedback form');
  const $radioInput = $feedbackForm.find('.wpcf7-radio');

  onChange($radioInput.find('input:checked'));

  $radioInput.find('input').on('change', (e) => {
    onChange(e.target);
  });
});
